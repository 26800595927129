import React, { useEffect } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import { Error } from "../components/error"

const Callback = () => {
  const { isAuthenticated, isLoading, error, user, logout } = useAuth0()

  useEffect(() => {
    if (user === undefined) return
    if (isAuthenticated && !user["https://api.codelinaro.org/0/role"]) {
      sessionStorage.theAuthUser = JSON.stringify(user)
      logout({
        returnTo: `${process.env.AUTH0_LOGOUT}`,
      })
      // setTimeout(() => {
      //   window.location.href = `${process.env.AUTH0_LOGOUT}/registration/incomplete`
      // }, 1000)
    } else {
      delete sessionStorage.theAuthUser
    }
  }, [user])

  if (isLoading) {
    return <Loading />
  }

  return <>{error && <Error message={error.message} />}</>
}

export default Callback
